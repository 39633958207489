<template>
    <div class="modal thrive_office secondary" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal">
        <div class="modal_container">
            <button class="close_btn" @click="closeModal"><i class="fas fa-times"></i></button>
            <div class="content">
                <div class="left">
                    <h2>Get live help every day with our team on Thrive Office Hours<sup>TM</sup></h2>
                    <ul>
                        <li>Feature Walkthroughs</li>
                        <li>Account Troubleshooting</li>
                        <li>Technical Help</li>
                    </ul>
                    <h4>Monday-Friday from 3-4 pm <label>Eastern Time, USA</label></h4>
                </div>
                <div class="right">
                    <img src="@/assets/images/help-center.svg" alt="">
                    <a class="join_btn" href="https://us02web.zoom.us/j/84104396321?pwd=bWRnQWlnZ3ZZZVMyeFZkYjZybVV6dz09" target="_blank">Click to Join <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Thrive Office',

    data () {
        return  {

        }
    },

    props: {
        modelValue: Boolean,
    },

    emits: ['update:modelValue'],

    methods:{
        closeModal () {
            const vm = this;

            vm.step = 0;
            vm.$emit('update:modelValue', false);
        },
    }
}
</script>

<style scoped>
.modal.thrive_office > .modal_container {
    width: 700px;
    height: auto;
    padding: 40px;
}
.content{
    display: flex;
}
.content .left{
    width: auto;
    flex-grow: 1;
    margin-right: 30px;
}
.content .right{
    width: 150px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.modal.thrive_office h2 {
    font-size: 22px;
    line-height: 34px;
    color: #121525;
    font-weight: 500;
    margin-bottom: 20px;
}
.modal.thrive_office h2 sup{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    padding-left: 2px;
}
.content .left ul{
    margin-bottom: 30px;
}
.content .left ul li{
    padding: 7px 10px 7px 20px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #121525;
    position: relative;
}
.content .left ul li:before{
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 6px solid transparent;
    border-right: 0;
    border-bottom: 6px solid transparent;
    border-left: 8px solid #666;
}
.modal.thrive_office h4{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    padding-bottom: 10px;
}
.modal.thrive_office h4 label{
    display: inline-block;
    margin-left: 3px;
    padding: 3px 10px;
    background: #e9e9e9;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
}
.content .right img{
    max-width: 130px;
    height: auto; 
    margin: auto;
}
.join_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    background: #2f7eed;
    border-radius: 4px;
    padding: 0 15px;
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
}
.join_btn i{
    padding-left: 10px;
}
.close_btn{
    border: 0;
    background: #dbdbdb;
    font-size: 12px;
    color: #121525;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
@media(max-width: 767px){
    .modal.thrive_office > .modal_container{
        width: 350px;
        padding: 30px;
    }
    .content{
        flex-wrap: wrap;
    }
    .content .left{
        width: 100%;
        margin: 0;
    }
    .content .right{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;
        align-items: flex-start;
    }
    .modal.thrive_office h2{
        font-size: 20px;
        line-height: 30px;
    }
    .content .left ul li{
        font-size: 13px;
        line-height: 20px;
    }
    .content .left ul li:before {
        top: 10px;
        border-top: 5px solid transparent;
        border-right: 0;
        border-bottom: 5px solid transparent;
        border-left: 7px solid #666;
    }
    .modal.thrive_office h4{
        font-size: 12px;
        line-height: 17px;
    }
    .content .right img{
        display: none;
    }
    .join_btn{
        height: 40px;
        padding: 0 20px;
    }
}
</style>